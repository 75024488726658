import React, { useState, useEffect } from 'react';
import { Slider } from '@/components/ui/slider.tsx';
import { Button } from '@/components/ui/button.tsx';
import { X } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext.tsx';
import { setupTelegramMainButton } from '../utils/telegram.ts';
import { useProducts } from '../contexts/ProductContext.tsx';

interface FilterPageProps {
    onApply: (priceRange: [number, number], selectedColors: string[]) => void;
    initialPriceRange?: [number, number];
    initialColors?: string[];
}

const FilterPage: React.FC<FilterPageProps> = ({
                                                   onApply,
                                                   initialPriceRange = [1000, 8000],
                                                   initialColors = []
                                               }) => {
    const { isDark, themeParams } = useTheme();
    const { availableFilters } = useProducts();
    const minPrice = availableFilters?.price_range?.min_price || 0;
    const maxPrice = availableFilters?.price_range?.max_price || 10000;
    const isPriceRangeEqual = availableFilters && minPrice === maxPrice;
    const [priceRange, setPriceRange] = useState<[number, number]>([
        Math.max(minPrice, initialPriceRange[0]),
        Math.min(maxPrice, initialPriceRange[1])
    ]);
    const [selectedColors, setSelectedColors] = useState<string[]>(initialColors);

    const colors = availableFilters?.colors.map(color => ({
        id: color.slug,
        name: color.name,
        hex: color.hex_code
    })) || [];

    // Set up Telegram's main button for filter application
    useEffect(() => {
        setupTelegramMainButton({
            text: 'Применить фильтры',
            color: themeParams.button_color || '#3390EC',
            textColor: themeParams.button_text_color || '#FFFFFF',
            isVisible: true,
            isActive: true,
            onClick: () => handleApplyFilters()
        });

        return () => {
            setupTelegramMainButton({
                isVisible: false
            });
        };
    }, [priceRange, selectedColors, themeParams]);

    const handleColorSelect = (colorName: string): void => {
        if (selectedColors.includes(colorName)) {
            setSelectedColors(selectedColors.filter(color => color !== colorName));
        } else {
            setSelectedColors([...selectedColors, colorName]);
        }
    };

    const handlePriceChange = (value: number[]): void => {
        const newMin = Math.max(minPrice, value[0]);
        const newMax = Math.min(maxPrice, value[1]);
        setPriceRange([newMin, newMax]);
    };

    const handleApplyFilters = (): void => {
        onApply(priceRange, selectedColors);
    };

    return (
        <div className={`h-screen flex flex-col ${isDark ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            <div className="flex-1 flex flex-col p-4 overflow-hidden">
                <div className="flex-1 overflow-y-auto">
                    <div className="mb-6">
                        <h2 className="text-lg mb-4">Цена</h2>
                        {availableFilters && !isPriceRangeEqual && (
                            <>
                                <div className="flex justify-between mb-2">
                                    <span>₽{priceRange[0]}</span>
                                    <span>₽{priceRange[1]}</span>
                                </div>
                                <Slider
                                    defaultValue={priceRange}
                                    min={minPrice}
                                    max={maxPrice}
                                    step={100}
                                    onValueChange={handlePriceChange}
                                    className="mt-2"
                                />
                            </>
                        )}
                        {availableFilters && isPriceRangeEqual && (
                            <div className="text-gray-500">
                                Цена: ₽{minPrice}
                            </div>
                        )}
                        {!availableFilters && (
                            <div className="text-gray-500">
                                Загрузка цен...
                            </div>
                        )}
                    </div>

                    <div className="mb-6">
                        <h2 className="text-lg mb-4">Цвета</h2>
                        <div className="flex flex-wrap gap-2">
                            {colors.map((color) => (
                                <div
                                    key={color.id}
                                    onClick={() => handleColorSelect(color.name)}
                                    className={`rounded-full px-4 py-2 flex items-center cursor-pointer ${
                                        selectedColors.includes(color.name)
                                            ? 'bg-blue-500 text-white'
                                            : isDark ? 'bg-gray-800 text-gray-300' : 'bg-gray-200 text-gray-700'
                                    }`}
                                >
                                    <div
                                        className="w-4 h-4 rounded-full mr-2"
                                        style={{
                                            backgroundColor: color.hex,
                                            border: color.id === 'white' ? '1px solid #ccc' : 'none'
                                        }}
                                    ></div>
                                    {color.name}
                                    {selectedColors.includes(color.name) && (
                                        <X className="w-4 h-4 ml-2" />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <Button
                    onClick={handleApplyFilters}
                    className="w-full py-3 rounded-md mt-4"
                    style={{
                        backgroundColor: themeParams.button_color || '#3390EC',
                        color: themeParams.button_text_color || '#FFFFFF'
                    }}
                >
                    Применить фильтры
                </Button>
            </div>
        </div>
    );
};

export default FilterPage;