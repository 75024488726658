import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import OrderDetails from '../components/OrderDetails';
import { Order } from '../api/types';
import { api } from '../api/hooks/useApi';
import { formatDate } from '../utils/date';
import BottomNavigation from '../components/BottomNavigation';
import { setupTelegramBackButton, setupTelegramTitle } from '@/utils/telegram';
import { styles } from '../styles/styles';
import { useTheme } from '../contexts/ThemeContext';

const OrdersList: React.FC<{ onBackClick: () => void }> = ({ onBackClick }) => {
  const { isDark } = useTheme();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await api.getOrders();
        setOrders(data);
      } catch (err) {
        setError('Ошибка при загрузке заказов');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    setupTelegramBackButton(false, onBackClick);
    setupTelegramTitle('Заказы');
  }, [onBackClick]);

  if (loading) {
    return <div className="text-center py-4">Загрузка заказов...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 py-4">{error}</div>;
  }

  if (orders.length === 0) {
    return <div className="text-center py-4">У вас пока нет заказов</div>;
  }

  return (
    <div className="px-4 pt-2 w-full">
      <div className="flex-1 flex flex-col -mx-4 overflow-hidden">
        <div className={`flex-1 overflow-y-auto ${isDark ? 'bg-gray-800 divide-gray-700' : 'bg-white divide-gray-200'}`}>
          {orders.map((order) => (
            <Link
              to={`/orders/${order.id}`}
              key={order.id}
              className={`block no-underline hover:no-underline ${isDark ? 'text-white hover:text-white' : 'text-gray-900 hover:text-gray-900'}`}
            >
              <div className="flex flex-col gap-1 border-b border-gray-200 px-4 py-4">
                <div className="flex justify-between items-center">
                  <h3 className={styles.typography.body}>Заказ №{order.id}</h3>
                  <span className={`px-3 py-1 rounded-full text-sm ${
                    order.status === 'new' ? 'bg-gray-100' : 
                    order.status === 'delivered' ? 'bg-blue-500 text-white' : 
                    'bg-gray-100'
                  }`}>
                    {order.status_display}
                  </span>
                </div>
                <div className={styles.typography.price.large}>₽ {order.total_amount}</div>
                <div className={styles.typography.small + ' text-[#85858B]'}>
                  Дата оформления: {formatDate(order.created_at)}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

const OrderListPage: React.FC<{ onBackClick: () => void }> = ({ onBackClick }) => {
  const { isDark } = useTheme();
  
  return (
    <div className={styles.containers.page(isDark)}>
      <div className="px-4 pt-2 w-full">
        <Routes>
          <Route path="/" element={<OrdersList onBackClick={onBackClick} />} />
          <Route path=":orderId" element={<OrderDetails onBackClick={onBackClick} />} />
        </Routes>
      </div>
      <div className="pb-20">
        <BottomNavigation />
      </div>
    </div>
  );
};

export default OrderListPage; 