import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { CartProvider } from './contexts/CartContext';
import { ProductProvider } from './contexts/ProductContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { HistoryProvider } from './contexts/HistoryContext';
import ProductListingPage from './pages/ProductListingPage';
import ProductDetailPage from './pages/ProductDetailPage';
import FilterPage from './pages/FilterPage';
import CartPage from './pages/CartPage';
import { initTelegramApp, isTelegramWebApp, tg } from './utils/telegram';
import { useNavigation } from './hooks/useNavigation';
import { AuthErrorHandler } from './components/AuthErrorHandler';
import NetworkErrorHandler from './components/NetworkErrorHandler';
import { config } from './config';
import OrderListPage from './pages/OrderListPage';
import CheckoutPage from './pages/CheckoutPage';

// Отдельный компонент для страницы продукта
const ProductDetailPageWrapper: React.FC = () => {
    const { id } = useParams();
    const navigation = useNavigation();
    
    console.log('ProductDetailPageWrapper - id from params:', id);
    const productId = id ? parseInt(id, 10) : 0;
    console.log('ProductDetailPageWrapper - parsed productId:', productId);

    return (
        <ProductDetailPage 
            productId={productId}
            onBackClick={navigation.onBackClick}
            onCartClick={navigation.onCartClick}
        />
    );
};

const AppContent: React.FC = () => {
    const navigation = useNavigation();
    const [isInitialized, setIsInitialized] = useState(false);
    const [initError, setInitError] = useState<string | null>(null);

    useEffect(() => {
        const initializeApp = async () => {
            if (config.isDevelopment && !isTelegramWebApp()) {
                // В режиме разработки без Telegram просто инициализируем приложение
                setIsInitialized(true);
                return;
            }

            try {
                await initTelegramApp();
                setIsInitialized(true);
            } catch (error) {
                console.error('Failed to initialize Telegram app:', error);
                setInitError('Не удалось инициализировать приложение. Пожалуйста, попробуйте позже.');
            }
        };

        initializeApp();
    }, []);

    if (!isInitialized) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
                    <p className="mt-4 text-gray-600">Инициализация приложения...</p>
                </div>
            </div>
        );
    }

    if (initError) {
        return (
            <div className="min-h-screen flex items-center justify-center p-4">
                <div className="max-w-md w-full p-6 rounded-lg shadow-lg bg-white">
                    <div className="text-center">
                        <div className="mb-4">
                            <svg className="mx-auto h-12 w-12 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                        </div>
                        <h2 className="text-xl font-semibold mb-2 text-gray-900">
                            Ошибка инициализации
                        </h2>
                        <p className="mb-4 text-gray-600">
                            {initError}
                        </p>
                        <button
                            onClick={() => window.location.reload()}
                            className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white"
                        >
                            Попробовать снова
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={
                <ProductListingPage 
                    onProductSelect={navigation.onProductSelect}
                />
            } />
            <Route path="/product/:id" element={<ProductDetailPageWrapper />} />
            <Route path="/filter" element={
                <FilterPage 
                    onApply={navigation.onApply}
                />
            } />
            <Route path="/cart" element={
                <CartPage 
                    onBackClick={navigation.onBackClick}
                />
            } />
            <Route path="/orders/*" element={<OrderListPage onBackClick={navigation.onBackClick} />} />
            <Route path="/checkout" element={<CheckoutPage />} />
        </Routes>
    );
};

const App: React.FC = () => {
    useEffect(() => {
        if (!isTelegramWebApp()) {
            // Если приложение открыто не в Telegram, показываем сообщение об ошибке
            document.body.innerHTML = `
                <div style="padding: 20px; text-align: center;">
                    <p>Это приложение доступно только через Telegram.</p>
                    <p style="margin-top: 10px; font-size: 12px; color: #666;">
                        initData: ${tg?.initData || 'недоступен'}
                    </p>
                </div>
            `;
            return;
        }
        initTelegramApp();
    }, []);

    // Рендерим приложение только если оно запущено в Telegram
    if (!isTelegramWebApp()) {
        return null;
    }

    return (
        <ThemeProvider>
            <ProductProvider>
                <CartProvider>
                    <HistoryProvider>
                        <Router>
                            <NetworkErrorHandler>
                                <AuthErrorHandler />
                                <AppContent />
                            </NetworkErrorHandler>
                        </Router>
                    </HistoryProvider>
                </CartProvider>
            </ProductProvider>
        </ThemeProvider>
    );
};

export default App;