import React, { createContext, useContext, useState } from 'react';

type HistoryItem = {
    type: 'product' | 'listing';
    id?: number;
};

interface HistoryContextType {
    history: HistoryItem[];
    addToHistory: (item: HistoryItem) => void;
    getLastItem: () => HistoryItem | null;
    clearHistory: () => void;
    clickCount: number;
    setClickCount: (count: number) => void;
}

const HistoryContext = createContext<HistoryContextType | undefined>(undefined);

export const HistoryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [history, setHistory] = useState<HistoryItem[]>([]);
    const [clickCount, setClickCount] = useState(0);

    const addToHistory = (item: HistoryItem) => {
        console.log('Adding to history:', item);
        setHistory(prev => {
            const newHistory = [...prev, item];
            console.log('New history state:', newHistory);
            return newHistory;
        });
    };

    const getLastItem = () => {
        const lastItem = history.length > 0 ? history[history.length - 1] : null;
        console.log('Getting last history item:', lastItem);
        return lastItem;
    };

    const clearHistory = () => {
        console.log('Clearing history');
        setHistory([]);
    };

    return (
        <HistoryContext.Provider value={{ 
            history, 
            addToHistory, 
            getLastItem, 
            clearHistory,
            clickCount,
            setClickCount
        }}>
            {children}
        </HistoryContext.Provider>
    );
};

export const useHistory = () => {
    const context = useContext(HistoryContext);
    if (context === undefined) {
        throw new Error('useHistory must be used within a HistoryProvider');
    }
    return context;
}; 