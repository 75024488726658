import axios, { AxiosInstance, AxiosError } from 'axios';
import { AuthResponse, CartResponse, Order, Product, FilterResponse, ProductsResponse, ProductsFilterParams, OrderDetails, OrderForm, OrderFormResponse } from './types';

const TOKEN_STORAGE_KEY = 'auth_token';

export class ApiClient {
  private baseUrl: string;
  private token: string | null = null;
  private axiosInstance: AxiosInstance;
  private isRefreshing = false;
  private refreshSubscribers: ((token: string) => void)[] = [];

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.axiosInstance = axios.create({
      baseURL: this.baseUrl,
      timeout: 10000, // 10 секунд таймаут
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    // Загружаем токен из localStorage при инициализации
    const storedToken = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (storedToken) {
      this.token = storedToken;
    }

    // Настраиваем перехватчик запросов
    this.axiosInstance.interceptors.request.use(
      (config) => {
        if (this.token) {
          config.headers.Authorization = `Bearer ${this.token}`;
        }
        return config;
      },
      (error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
      }
    );

    // Настраиваем перехватчик ответов
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        // Обработка ошибок сети
        if (!error.response) {
          console.error('Network error:', error);
          
          // Определяем тип запроса и отправляем соответствующее сообщение
          const url = error.config?.url || '';
          let errorMessage = 'Сервер недоступен. Пожалуйста, проверьте подключение к интернету.';
          
          if (url.includes('/api/auth/')) {
            errorMessage = 'Не удалось подключиться к серверу авторизации. Пожалуйста, попробуйте позже или обратитесь в поддержку.';
          } else if (url.includes('/api/orders/')) {
            errorMessage = 'Не удалось подключиться к серверу заказов. Пожалуйста, попробуйте позже.';
          } else if (url.includes('/api/get_products/')) {
            errorMessage = 'Не удалось загрузить товары. Пожалуйста, проверьте подключение к интернету.';
          }
          
          // Отправляем событие для обработки в приложении
          window.dispatchEvent(new CustomEvent('network-error', { detail: errorMessage }));
          return Promise.reject(error);
        }

        const originalRequest = error.config;
        
        // Проверяем, что это ошибка 401 и запрос не является запросом на аутентификацию
        if (error.response?.status === 401 && 
            originalRequest && 
            originalRequest.url !== '/api/auth/' &&
            !(originalRequest as any)._retry) {
          
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            
            try {
              // Пытаемся получить новый токен
              const newToken = await this.refreshToken();
              this.token = newToken;
              localStorage.setItem(TOKEN_STORAGE_KEY, newToken);
              
              // Уведомляем всех подписчиков о новом токене
              this.onRefreshSuccess(newToken);
              
              // Повторяем оригинальный запрос с новым токеном
              (originalRequest as any)._retry = true;
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              return this.axiosInstance(originalRequest);
            } catch (refreshError) {
              // Если не удалось обновить токен, очищаем его
              this.token = null;
              localStorage.removeItem(TOKEN_STORAGE_KEY);
              this.onRefreshFailure(refreshError);
              return Promise.reject(refreshError);
            } finally {
              this.isRefreshing = false;
            }
          }

          // Если обновление уже идет, добавляем запрос в очередь
          return new Promise(resolve => {
            this.refreshSubscribers.push((token: string) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(this.axiosInstance(originalRequest));
            });
          });
        }

        // Обработка других ошибок
        console.error('API error:', error.response.data);
        return Promise.reject(error);
      }
    );
  }

  private onRefreshSuccess(token: string): void {
    this.refreshSubscribers.forEach(callback => callback(token));
    this.refreshSubscribers = [];
  }

  private onRefreshFailure(error: any): void {
    this.refreshSubscribers = [];
    this.token = null;
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    window.dispatchEvent(new CustomEvent('auth-error', { detail: error }));
  }

  private async refreshToken(): Promise<string> {
    try {
      // Получаем новые данные инициализации из Telegram WebApp
      const webApp = (window as any).Telegram?.WebApp;
      if (!webApp) {
        throw new Error('Telegram WebApp is not available');
      }
      
      const initData = webApp.initData;
      const response = await this.axiosInstance.post<AuthResponse>('/api/auth/', {
        telegram_init_data: initData
      });
      
      return response.data.access_token;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      throw error;
    }
  }

  // Методы работы с корзиной
  async addToCart(productId: string, quantity: number): Promise<CartResponse> {
    const response = await this.axiosInstance.post('/api/add-to-cart/', { product_id: productId, quantity });
    return response.data;
  }

  async getCart(): Promise<CartResponse> {
    const response = await this.axiosInstance.get('/api/get-cart/');
    return response.data;
  }

  async changeQuantity(productId: string, quantity: number): Promise<CartResponse> {
    const response = await this.axiosInstance.post('/api/change-quantity/', {
      product_id: productId,
      quantity
    });
    return response.data;
  }

  async removeFromCart(productId: string): Promise<CartResponse> {
    const response = await this.axiosInstance.post('/api/remove-from-cart/', {
      product_id: productId
    });
    return response.data;
  }

  // Методы работы с заказами
  async getOrders(): Promise<Order[]> {
    const response = await this.axiosInstance.get('/api/get-orders/');
    return response.data;
  }

  async getOrder(orderId: number): Promise<OrderDetails> {
    const response = await this.axiosInstance.get(`/api/get-order/${orderId}/`);
    return response.data;
  }

  async createOrder(orderData: OrderForm): Promise<OrderDetails> {
    const response = await this.axiosInstance.post('/api/create-order/', orderData);
    return response.data;
  }

  async uploadReceipt(orderId: number, file: File): Promise<{ receipt_url: string }> {
    const formData = new FormData();
    formData.append('receipt', file);
    
    const response = await this.axiosInstance.post(
      `/api/orders/${orderId}/upload-receipt/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  }

  async getOrderForm(): Promise<OrderFormResponse> {
    const response = await this.axiosInstance.get<OrderFormResponse>('/api/get-order-form/');
    return response.data;
  }

  // Методы работы с товарами
  async getFilters(): Promise<FilterResponse> {
    const response = await this.axiosInstance.get('/api/get_filters/');
    return response.data;
  }

  async getProducts(filterParams: ProductsFilterParams): Promise<ProductsResponse> {
    const response = await this.axiosInstance.get('/api/get_products/', {
      params: filterParams
    });
    return response.data;
  }

  async getProduct(productId: string): Promise<Product> {
    const response = await this.axiosInstance.get(`/api/get_product/${productId}/`);
    return response.data;
  }

  // Методы аутентификации
  async authenticate(telegramInitData: string): Promise<AuthResponse> {
    try {
      const response = await this.axiosInstance.post<AuthResponse>('/api/auth/', {
        telegram_init_data: telegramInitData
      });
      this.token = response.data.access_token;
      localStorage.setItem(TOKEN_STORAGE_KEY, response.data.access_token);
      
      // Проверяем текущий URL перед редиректом
      const currentPath = window.location.pathname;
      if (currentPath === '/' || currentPath === '/auth') {
        window.location.href = '/';
      }
      
      return response.data;
    } catch (error) {
      this.token = null;
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      throw error;
    }
  }
}
