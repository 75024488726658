import React, { useEffect, useState } from 'react';
import { useApi } from '../api/hooks/useApi';
import { OrderForm, OrderFormResponse } from '../api/types';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import BottomNavigation from '../components/BottomNavigation';
import { useTheme } from '../contexts/ThemeContext';
import { styles } from '../styles/styles';

const CheckoutPage: React.FC = () => {
  const { api, isAuthenticated } = useApi();
  const { cart } = useCart();
  const { isDark, themeParams } = useTheme();
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState<OrderFormResponse | null>(null);
  const [formData, setFormData] = useState<OrderForm>({
    first_name: '',
    last_name: '',
    middle_name: '',
    phone: '',
    email: '',
    delivery_method: '',
    delivery_address: ''
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchFormFields = async () => {
      try {
        const response = await api.getOrderForm();
        setFormFields(response);
        // Устанавливаем начальные значения из ответа
        setFormData({
          first_name: response.first_name.value,
          last_name: response.last_name.value,
          middle_name: response.middle_name.value,
          phone: response.phone.value,
          email: response.email.value,
          delivery_method: response.delivery_method.value,
          delivery_address: response.delivery_address.value
        });
        setIsLoading(false);
      } catch (error) {
        console.error('Ошибка при загрузке формы:', error);
        setIsLoading(false);
      }
    };

    fetchFormFields();
  }, [isAuthenticated]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!cart) return;

    try {
      const order = await api.createOrder(formData);
      navigate(`/orders/${order.order.id}`);
    } catch (error) {
      console.error('Ошибка при создании заказа:', error);
    }
  };

  const handleChange = (field: keyof OrderForm, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!formFields) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-600">Не удалось загрузить форму заказа</p>
      </div>
    );
  }

  return (
    <div className={styles.containers.page(isDark)}>
      <div className="px-4 pt-2 w-full">
        <h1 className="text-2xl font-bold mb-6">Оформление заказа</h1>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                {formFields.first_name.label}
              </label>
              <input
                type="text"
                required={formFields.first_name.required}
                maxLength={formFields.first_name.max_length}
                value={formData.first_name}
                onChange={(e) => handleChange('first_name', e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">
                {formFields.last_name.label}
              </label>
              <input
                type="text"
                required={formFields.last_name.required}
                maxLength={formFields.last_name.max_length}
                value={formData.last_name}
                onChange={(e) => handleChange('last_name', e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">
                {formFields.middle_name.label}
              </label>
              <input
                type="text"
                required={formFields.middle_name.required}
                maxLength={formFields.middle_name.max_length}
                value={formData.middle_name}
                onChange={(e) => handleChange('middle_name', e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">
                {formFields.phone.label}
              </label>
              <input
                type="tel"
                required={formFields.phone.required}
                maxLength={formFields.phone.max_length}
                value={formData.phone}
                onChange={(e) => handleChange('phone', e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">
                {formFields.email.label}
              </label>
              <input
                type="email"
                required={formFields.email.required}
                value={formData.email}
                onChange={(e) => handleChange('email', e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">
                {formFields.delivery_method.label}
              </label>
              <select
                required={formFields.delivery_method.required}
                value={formData.delivery_method}
                onChange={(e) => handleChange('delivery_method', e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="">Выберите способ доставки</option>
                {formFields.delivery_method.options?.map(option => (
                  <option key={option.name} value={option.name}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">
                {formFields.delivery_address.label}
              </label>
              <textarea
                required={formFields.delivery_address.required}
                value={formData.delivery_address}
                onChange={(e) => handleChange('delivery_address', e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                rows={3}
              />
            </div>
          </div>

          <div className="fixed bottom-20 left-0 right-0 p-4 border-t">
            <button
              type="submit"
              className="w-full py-3 px-4 rounded-md"
              style={{
                backgroundColor: themeParams.button_color || '#3390EC',
                color: themeParams.button_text_color || '#FFFFFF'
              }}
            >
              Оформить заказ
            </button>
          </div>
        </form>
      </div>
      <div className="pb-20">
        <BottomNavigation />
      </div>
    </div>
  );
};

export default CheckoutPage; 