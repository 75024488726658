import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OrderDetails as OrderDetailsType } from '../api/types';
import { api } from '../api/hooks/useApi';
import { formatDate } from '../utils/date';
import { setupTelegramBackButton } from '@/utils/telegram';
import { useTheme } from '../contexts/ThemeContext';
import { styles } from '../styles/styles';
import { Button } from './ui/button';

const OrderDetails: React.FC<{ onBackClick: () => void }> = ({ onBackClick }) => {
  const { isDark, themeParams } = useTheme();
  const { orderId } = useParams<{ orderId: string }>();
  const [orderDetails, setOrderDetails] = useState<OrderDetailsType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setupTelegramBackButton(true, onBackClick);
  }, [onBackClick]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!orderId) return;
      
      try {
        const data = await api.getOrder(parseInt(orderId));
        setOrderDetails(data);
      } catch (err) {
        setError('Ошибка при загрузке деталей заказа');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!orderId || !event.target.files?.length) return;
    
    const file = event.target.files[0];
    setUploading(true);
    
    try {
      const result = await api.uploadReceipt(parseInt(orderId), file);
      if (orderDetails) {
        setOrderDetails({
          ...orderDetails,
          order: {
            ...orderDetails.order,
            receipt_url: result.receipt_url,
          },
        });
      }
    } catch (err) {
      setError('Ошибка при загрузке чека');
      console.error(err);
    } finally {
      setUploading(false);
    }
  };

  if (loading) {
    return <div className="text-center py-4">Загрузка деталей заказа...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 py-4">{error}</div>;
  }

  if (!orderDetails) {
    return <div className="text-center py-4">Заказ не найден</div>;
  }

  const { order, items } = orderDetails;

  return (
    <div className={`${styles.containers.page(isDark)} pb-10`}>
      <div className="w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-[34px] font-inter leading-[41px] tracking-[-0.45px] font-bold">Заказ №{order.id}</h2>
          <span className={`px-3 py-1 rounded-full text-sm ${
            order.status === 'new' ? 'bg-gray-100' : 
            order.status === 'delivered' ? 'bg-blue-500 text-white' : 
            'bg-gray-100'
          }`}>
            {order.status_display}
          </span>
        </div>

        <div className="flex flex-col -mx-4 overflow-hidden">
          <div className={`flex-1 overflow-y-auto ${isDark ? 'bg-gray-800 divide-gray-700' : 'bg-white divide-gray-200'}`}>
            {items.map((item) => (
              <div key={item.id} className="flex w-full px-4 py-3">
                <div className="w-1/4 min-w-[80px] max-w-[100px] self-stretch flex items-center">
                  <img
                    src={item.preview_image}
                    alt={item.product_name}
                    className="w-full h-full object-cover rounded-xl"
                  />
                </div>
                <div className="flex-1 pl-4 flex flex-col">
                  <h3 className="font-normal mb-1 text-[17px] font-inter leading-[22px] tracking-[-0.41px]">
                    {item.product_name}
                  </h3>
                  <p className="font-bold text-[17px] font-inter leading-[22px] tracking-[-0.41px] mb-2">
                    ₽{item.price}
                  </p>
                  <div className="flex items-center justify-between mb-3">
                    <span className="text-[17px] font-inter leading-[22px] tracking-[-0.41px] font-medium">
                      <span className="text-[17px] font-inter leading-[22px] tracking-[-0.41px] text-[#85858B] font-normal">
                        шт:&nbsp;&nbsp;
                      </span>
                      {item.quantity}
                    </span>
                  </div>
                  <div className="h-px bg-gray-200 dark:bg-gray-700 w-full mt-auto" />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={`-mx-4 ${isDark ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="px-4 py-4">
            <h3 className="text-[22px] font-inter leading-[28px] tracking-[-0.35px] font-semibold mb-4">
              Детали заказа
            </h3>
            <div className="text-sm divide-y divide-gray-200">
              <div className="py-3 flex justify-between">
                <span className="text-[#85858B]">Дата оформления</span>
                <span>{formatDate(order.created_at)}</span>
              </div>
              <div className="py-3 flex justify-between">
                <span className="text-[#85858B]">Статус</span>
                <span>{order.status_display}</span>
              </div>
              <div className="py-3 flex justify-between">
                <span className="text-[#85858B]">Сумма заказа</span>
                <span className="font-medium">₽{order.total_amount}</span>
              </div>
              <div className="py-3 flex justify-between">
                <span className="text-[#85858B]">Способ доставки</span>
                <span>{order.delivery_method_display}</span>
              </div>
              <div className="py-3 flex justify-between">
                <span className="text-[#85858B]">Адрес доставки</span>
                <span className="text-left max-w-[60%] break-words text-right">{order.delivery_address}</span>
              </div>
              <div className="py-3 flex justify-between">
                <span className="text-[#85858B]">Получатель</span>
                <span>{order.last_name} {order.first_name} {order.middle_name}</span>
              </div>
              <div className="py-3 flex justify-between">
                <span className="text-[#85858B]">Телефон</span>
                <span>{order.phone}</span>
              </div>
              <div className="py-3 flex justify-between">
                <span className="text-[#85858B]">Email</span>
                <span>{order.email}</span>
              </div>
              {order.receipt_url && (
                <div className="py-3 flex justify-between">
                  <span className="text-[#85858B]">Чек</span>
                  <a
                    href={order.receipt_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="py-2 px-4 rounded-md"
                    style={{
                      backgroundColor: themeParams.button_color || '#3390EC',
                      color: themeParams.button_text_color || '#FFFFFF'
                    }}
                  >
                    Просмотреть
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {!order.receipt_url && (
        <div className={`fixed bottom-20 left-0 right-0 p-4 border-t ${
          isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
        }`}>
          <input
            type="file"
            accept=".pdf,.jpg,.jpeg,.png"
            onChange={handleFileUpload}
            disabled={uploading}
            className="hidden"
            id="receipt-upload"
          />
          <Button
            onClick={() => document.getElementById('receipt-upload')?.click()}
            className="w-full"
            style={{
              backgroundColor: themeParams.button_color || '#3390EC',
              color: themeParams.button_text_color || '#FFFFFF'
            }}
          >
            {uploading ? 'Загрузка...' : 'Прикрепить чек'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default OrderDetails; 