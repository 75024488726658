import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useHistory } from '../contexts/HistoryContext';

const BottomNavigation: React.FC = () => {
    const { isDark, themeParams, colors } = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { getLastItem, clearHistory, clickCount, setClickCount } = useHistory();

    const isActive = (path: string) => {
        return location.pathname === path || 
               (path === '/' && location.pathname === '/') ||
               (path === '/orders' && location.pathname.startsWith('/orders')) ||
               (path === '/cart' && location.pathname.startsWith('/cart'));
    };

    const getButtonStyles = (path: string) => {
        const active = isActive(path);
        
        if (active) {
            return {
                color: themeParams.button_text_color || colors['--button-text'],
                backgroundColor: themeParams.button_color || colors['--primary'],
                border: `2px solid ${themeParams.button_color || colors['--primary']}`,
            };
        }
        
        return {
            color: themeParams.text_color || colors['--text'],
            backgroundColor: 'transparent',
        };
    };

    const handleHomeClick = () => {
        console.log('Home button clicked, current count:', clickCount);
        const lastItem = getLastItem();
        console.log('Last history item:', lastItem);
        
        // Если мы на странице товара, всегда переходим на список
        if (location.pathname.startsWith('/product/')) {
            console.log('On product page - navigating to home page');
            navigate('/');
            setClickCount(0);
            return;
        }
        
        if (clickCount === 0) {
            if (lastItem?.type === 'product' && lastItem.id) {
                console.log('Navigating to last viewed product:', lastItem.id);
                navigate(`/product/${lastItem.id}`);
                setClickCount(1);
            } else {
                console.log('Navigating to home page');
                navigate('/');
                setClickCount(0);
            }
        } else {
            console.log('Second click - navigating to home page and clearing history');
            navigate('/');
            setClickCount(0);
            clearHistory();
        }
    };

    const handleProfileClick = () => {
        console.log('Profile button clicked');
        setClickCount(0);
        navigate('/orders');
    };

    const handleCartClick = () => {
        console.log('Cart button clicked');
        setClickCount(0);
        navigate('/cart');
    };

    return (
        <div className={`fixed bottom-0 left-0 right-0 p-4 ${
            isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
        }`}>
            <div className="flex justify-between items-center">
                <button
                    onClick={handleHomeClick}
                    className="flex flex-col items-center p-2 rounded-lg transition-all duration-200"
                    style={getButtonStyles('/')}
                >
                    <svg className="w-5 h-5 mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    <span className="text-xs">Главная</span>
                </button>

                <button
                    onClick={handleProfileClick}
                    className="flex flex-col items-center p-2 rounded-lg transition-all duration-200"
                    style={getButtonStyles('/orders')}
                >
                    <svg className="w-5 h-5 mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    <span className="text-xs">Кабинет</span>
                </button>

                <button
                    onClick={handleCartClick}
                    className="flex flex-col items-center p-2 rounded-lg transition-all duration-200"
                    style={getButtonStyles('/cart')}
                >
                    <svg className="w-5 h-5 mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                    <span className="text-xs">Корзина</span>
                </button>
            </div>
        </div>
    );
};

export default BottomNavigation; 