import React, { useEffect } from 'react';
import { MinusIcon, PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { Button } from '@/components/ui/button.tsx';
import { useTheme } from '../contexts/ThemeContext.tsx';
import { useCart } from '../contexts/CartContext.tsx';
import { useNavigate } from 'react-router-dom';
import BottomNavigation from '../components/BottomNavigation';
import { styles } from '../styles/styles';

interface CartItemProps {
    id: number;
    name: string;
    price: number;
    quantity: number;
    availableQuantity: number;
    image: string;
    onIncrease: (id: number) => void;
    onDecrease: (id: number) => void;
    onRemove: (id: number) => void;
}

const CartItem: React.FC<CartItemProps> = ({
    id,
    name,
    price,
    quantity,
    availableQuantity,
    image,
    onIncrease,
    onDecrease,
    onRemove
}) => {
    // const { isDark } = useTheme();
    const showWarning = availableQuantity < quantity;

    return (
        <div className="flex w-full">
            <div className="w-1/4 min-w-[80px] max-w-[100px] self-stretch flex items-center">
                <img
                    src={image}
                    alt={name}
                    className="w-full h-full object-cover rounded-xl"
                />
            </div>
            <div className="flex-1 pl-4 flex flex-col">
                <h3 className="font-normal mb-1 text-[17px] font-inter leading-[22px] tracking-[-0.41px]">{name}</h3>
                <p className="font-bold text-[17px] font-inter leading-[22px] tracking-[-0.41px] mb-2">₽{price}</p>
                <div className="flex items-center justify-between mb-3">
                    <div className="flex flex-col">
                        <span className="text-[17px] font-inter leading-[22px] tracking-[-0.41px] font-medium">
                            <span className="text-[17px] font-inter leading-[22px] tracking-[-0.41px] text-[#85858B] font-normal">
                                шт:&nbsp;&nbsp;
                            </span>
                            {quantity}
                        </span>
                        {showWarning && (
                            <span className="text-red-500 text-sm mt-1">
                                Доступно всего: {availableQuantity}
                            </span>
                        )}
                    </div>
                    {availableQuantity > 0 ? (
                        <div className="flex items-center rounded-lg overflow-hidden bg-gray-200 dark:bg-gray-700">
                            <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => onDecrease(id)}
                                className="w-6 h-6 flex items-center justify-center rounded-none border-none bg-gray-200 dark:bg-gray-700"
                            >
                                <MinusIcon className="w-3 h-3 text-[#85858B]" />
                            </Button>
                            <div className="h-4 w-px bg-gray-300 dark:bg-gray-100"></div>
                            <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => onIncrease(id)}
                                disabled={quantity >= availableQuantity}
                                className="w-6 h-6 flex items-center justify-center rounded-none border-none bg-gray-200 dark:bg-gray-700"
                            >
                                <PlusIcon className="w-3 h-3 text-[#85858B]" />
                            </Button>
                        </div>
                    ) : (
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => onRemove(id)}
                            className="w-6 h-6 flex items-center justify-center rounded-lg"
                        >
                            <TrashIcon className="w-4 h-4 text-red-500" />
                        </Button>
                    )}
                </div>
                <div className="h-px bg-gray-200 dark:bg-gray-700 w-full mt-auto" />
            </div>
        </div>
    );
};

interface CartPageProps {
    onBackClick: () => void;
}

const CartPage: React.FC<CartPageProps> = ({
    onBackClick
}) => {
    const { isDark, themeParams } = useTheme();
    const { cart, changeQuantity, refreshCart } = useCart();
    const navigate = useNavigate();

    useEffect(() => {
        refreshCart();
    }, []);

    const handleCheckout = () => {
        navigate('/checkout');
    };

    const handleQuantityChange = (productId: string, newQuantity: number, availableQuantity: number) => {
        const item = cart?.items.find(item => item.product_id.toString() === productId);
        if (item) {
            if (newQuantity > availableQuantity) {
                newQuantity = availableQuantity;
            }
            changeQuantity(productId, newQuantity);
        }
    };

    return (
        <div className={styles.containers.page(isDark)}>
            <div className="px-4 pt-2 w-full">
                <div className="mb-6">
                    {cart?.items.length === 0 ? (
                        <div className={`flex-1 flex flex-col items-center justify-center p-6 text-center rounded-lg ${
                            isDark ? 'bg-gray-800' : 'bg-white'
                        }`}>
                            <p className="mb-4">Ваша корзина пуста</p>
                            <Button
                                onClick={onBackClick}
                                className="py-2 px-4 rounded-md"
                                style={{
                                    backgroundColor: themeParams.button_color || '#3390EC',
                                    color: themeParams.button_text_color || '#FFFFFF'
                                }}
                            >
                                Перейти к покупкам
                            </Button>
                        </div>
                    ) : (
                        <div className="flex-1 flex flex-col p-4 -mx-4 overflow-hidden">
                            <div className={`flex-1 overflow-y-auto ${
                                isDark ? 'bg-gray-800 divide-gray-700' : 'bg-white divide-gray-200'
                            }`}>
                                {cart?.items.map((item) => (
                                    <CartItem
                                        key={item.product_id}
                                        id={item.product_id}
                                        name={item.product_name}
                                        price={item.price}
                                        quantity={item.quantity}
                                        availableQuantity={item.available_quantity}
                                        image={item.preview_image}
                                        onIncrease={(id) => handleQuantityChange(id.toString(), item.quantity + 1, item.available_quantity)}
                                        onDecrease={(id) => {
                                            if (item.quantity > item.available_quantity) {
                                                handleQuantityChange(id.toString(), item.available_quantity, item.available_quantity);
                                            } else {
                                                handleQuantityChange(id.toString(), item.quantity - 1, item.available_quantity);
                                            }
                                        }}
                                        onRemove={(id) => handleQuantityChange(id.toString(), 0, item.available_quantity)}
                                    />
                                ))}
                            </div>

                            <div className="mt-4">
                                <div className="flex justify-between mb-2">
                                    <span className={isDark ? 'text-gray-300' : 'text-gray-600'}>Итого:</span>
                                    <span className="font-bold">₽{cart?.total_price}</span>
                                </div>
                                <div className="flex justify-between items-center text-sm">
                                    <span className={isDark ? 'text-gray-300' : 'text-gray-600'}>Доставка:</span>
                                    <span className={isDark ? 'text-gray-300' : 'text-gray-600'}>Рассчитывается отдельно</span>
                                </div>
                            </div>

                            <div className={`fixed bottom-20 left-0 right-0 p-4 border-t ${
                                isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                            }`}>
                                <Button
                                    onClick={handleCheckout}
                                    className="w-full py-3 rounded-md"
                                    style={{
                                        backgroundColor: themeParams.button_color || '#3390EC',
                                        color: themeParams.button_text_color || '#FFFFFF'
                                    }}
                                >
                                    Продолжить
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="pb-20">
                <BottomNavigation />
            </div>
        </div>
    );
};

export default CartPage;